@import 'vars';

h1,
.h1 {
  font-size: 3.5rem;
  line-height: 120%;
  letter-spacing: -0.01em;

  @media (max-width: $breakpoint-sm) {
    font-size: 2.25rem;
  }
}

h2,
.h2 {
  font-size: 2.25rem;
  line-height: 120%;

  @media (max-width: $breakpoint-sm) {
    font-size: 1.625rem;
  }
}

h3,
.h3 {
  font-size: 1.625rem;
  line-height: 130%;
}

h4,
.h4 {
  font-size: 1.25rem;
  line-height: 130%;
}

h5,
.h5 {
  font-size: 1.125rem;
  line-height: 130%;
}

p,
.p-l {
  font-size: 0.938rem;
  line-height: 150%;

  @media (max-width: $breakpoint-sm) {
    font-size: 1.125rem;
  }
}

.p-m {
  font-size: 0.813rem;
  line-height: 130%;

  @media (max-width: $breakpoint-sm) {
    font-size: 0.938rem;
  }
}

.p-s {
  font-size: 0.688rem;
  line-height: 135%;

  @media (max-width: $breakpoint-sm) {
    font-size: 0.813rem;
  }
}

.caption {
  font-size: 0.625rem;
  line-height: 135%;

  @media (max-width: $breakpoint-sm) {
    font-size: 0.688rem;
  }
}

.bold,
h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  &:not(.no-bold) {
    font-weight: 600;
  }
}
