.accordion {
  overflow: hidden;
}
/* Accordion default header styles */
.accordion__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1.25rem;
  padding-top: 0.625rem;
  cursor: pointer;
}
.accordion__item {
  display: flex;
  margin-bottom: 1rem;

  hr {
    display: block;
    flex-grow: 1;
    margin: 0;
    margin-top: 0.25rem;
    border-left-width: 0;
  }

  &:last-child:not(.active) hr {
    display: none;
  }
}

/* Default toggle button styles */
.accordion__toggle-btn {
  border: none;
  transition: transform 0.3s ease-in;
  background: none;
  color: var(--steps-toggle-button-color, var(--c-primary-60));
}

/* Manage disabled state of the Accordion item */
.accordion__item.disabled .accordion__header {
  color: var(--accordion-disabled);
  cursor: not-allowed;
}
.accordion__toggle-btn:disabled {
  cursor: not-allowed;
}
.accordion__toggle-btn:disabled svg path:nth-child(2) {
  fill: var(--accordion-disabled);
}
.accordion__item.active .accordion__toggle-btn {
  transform: rotate(180deg);
}

.accordion__toggle-btn:focus {
  outline: none;
}
