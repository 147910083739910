@keyframes spinner-wrapper-animation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
    display: none;
  }
}

@keyframes spinner-animation {
  to {
    transform: rotate(360deg);
  }
}

.spinner-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;

  &__opacity {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.ready .spinner-wrapper {
  animation: spinner-wrapper-animation 1s forwards;
  animation-delay: 0.75s;
}

.spinner-wrapper .spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  border-radius: 50%;
  border: 4px solid #ccc;
  border-top-color: var(--spinner-color, var(--c-primary-100));
  animation: spinner-animation 0.6s linear infinite;
}
