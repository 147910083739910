@import 'vars';

@function transform-number-to-string($value) {
  @if type-of($value) == 'number' {
    @return #{$value};
  } @else if type-of($value) == 'string' {
    @return $value;
  } @else {
    @error 'Input #{$value} is no number or string';
  }
}

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

$spaces: (0.25, 0.5, 1, 2, 3, 4, 5, 6);

@mixin gen-rem($prefix, $properties) {
  @each $value in $spaces {
    .#{$prefix}-#{str-replace(transform-number-to-string($value), '.', '')} {
      @each $property in $properties {
        #{$property}: #{$value}rem;
      }
    }
  }
}

@include gen-rem('gap', ('gap'));
@include gen-rem('mt', ('margin-top'));
@include gen-rem('pb', ('padding-bottom'));
@include gen-rem('px', ('padding-left', 'padding-right'));
@include gen-rem('py', ('padding-top', 'padding-bottom'));

.w-100 {
  width: 100%;
}

.container {
  margin: 0 auto;
  padding: 0 1.25rem;
  max-width: 1200px;

  @media (min-width: $breakpoint-sm) {
    padding: 0 2rem;
  }
}

.d-flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-grow-1 {
  flex-grow: 1;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

.hide-mobile {
  @media (max-width: $breakpoint-sm) {
    display: none;
  }
}

.show-mobile {
  @media (min-width: $breakpoint-sm) {
    display: none;
  }
}
