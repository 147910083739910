:root {
  --c-primary-100: #00295a;
  --c-primary-60: #667f9c;
  --c-primary-40: #99a9bd;
  --c-primary-20: #ccd4de;
  --c-primary-10: #e5eaee;
  --c-primary-5: #f2f4f7;
  --c-secondary-solar: #ffa500;
  --c-secondary-battery: #20b7f1;
  --c-secondary-community: #ae0bc9;
  --c-tertiary-orange: #fe7500;
  --c-tertiary-red: #fc3200;
  --c-tertiary-green: #35cf3b;
  --c-black: #000000;
  --c-white: #ffffff;
}

$breakpoint-sm: 768px;
