@import 'styles/reset', 'styles/vars', 'styles/spinner', 'styles/typography', 'styles/util',
  'styles/accordion';

body {
  font-family: 'Instrument Sans', sans-serif;
  color: var(--text-color, var(--c-primary-100));
  font-weight: 500;
  letter-spacing: 0;
  max-width: 100%;
}

img {
  max-width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

.block-padding {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.main-bg {
  background-color: var(--main-bg-color, var(--c-primary-5));
}

alert {
  position: fixed;
  top: 2rem;
  right: 2rem;
}

.contact {
  &-container {
    @media (max-width: $breakpoint-sm) {
      flex-direction: column;
      gap: 2rem;
    }
  }
  &-column {
    flex: 0 1 calc(50% - 4rem);

    @media (max-width: $breakpoint-sm) {
      flex: 0 0 100%;
    }
  }

  &-person-image {
    max-height: 10rem;
    object-fit: contain;
  }
}

.mail-section {
  padding: 8rem 1rem;
  &__enabled {
    padding: 3rem 1rem;
  }
  color: var(--mail-section-text-color, var(--c-white));
  background-color: var(--mail-section-bg-color, var(--c-primary-100));

  svg {
    width: 26px;
    height: 26px;
    opacity: 0.8;
  }

  button {
    color: var(--c-primary-100);
    padding: 0.75rem 3rem;
    border-radius: 500px;
    outline: none;
    border: none;
    background: linear-gradient(0deg, rgba(0, 41, 90, 0.2) 0%, rgba(0, 41, 90, 0.2) 100%),
      var(--White, #fff);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
  }

  &__icon {
    width: 54px;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--mail-section-text-color, var(--c-white));
    color: var(--mail-section-bg-color, var(--c-primary-100));
    border-radius: 50%;
    flex: 0 0 54px;
  }

  &__content {
    max-width: 33rem;
  }
}

.header {
  &-container {
    @media (max-width: $breakpoint-sm) {
      flex-direction: column;
    }
  }
  &-column {
    flex: 0 1 50%;

    @media (max-width: $breakpoint-sm) {
      flex: 0 0 100%;
    }
  }

  &-logo {
    img {
      height: 100%;
    }
    height: 40px;

    @media (max-width: $breakpoint-sm) {
      height: 20px;
    }
  }
}

.steps {
  &-container {
    margin: 0 auto;
    background-color: var(--checklist-bg-color);

    @media (min-width: $breakpoint-sm) {
      width: fit-content;
      min-width: 580px;
    }

    @media (max-width: $breakpoint-sm) {
      .accordion__header {
        padding-top: 0.25rem;
      }
    }

    .step-title {
      color: var(--step-title-color, var(--c-primary-60));

      &.completed {
        color: var(--step-completed-title-color, var(--c-primary-100));
      }
    }

    .phase {
      &-title {
        color: var(--phase-title-color, var(--c-primary-100));

        &.completed {
          color: var(--phase-completed-title-color, var(--c-primary-60));
          font-size: 1.125rem;
        }
      }

      &-date {
        margin-top: 0.5rem;
        color: var(--date-color, var(--c-primary-60));
      }

      &-title,
      &-date {
        margin-left: 1rem;

        @media (min-width: $breakpoint-sm) {
          margin-left: 2rem;
        }
      }

      &-content {
        margin-left: 1rem;

        @media (min-width: $breakpoint-sm) {
          margin-left: 2rem;
        }
      }
    }
  }
}

.accordion__navigation-line {
  border-color: var(--steps-nav-line-color, var(--c-primary-40));
}

.address-card {
  margin-left: auto;
  padding: 0 16px 0 8px;
  color: var(--address-card-text-color, var(--c-primary-100));
  background-color: var(--address-card-bg-color, var(--c-primary-20));
  border-radius: 500px;
  display: flex;
  align-items: center;

  & img {
    height: 60px;
  }
}

.status-round {
  border: 1px solid var(--c-primary-5);
  box-shadow: 0 6px 12px 0 #00295a14;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 50%;
  color: var(--status-icon-color, var(--c-primary-60));
  background-color: var(--status-icon-bg-color);
  padding: 0.5rem;

  &__lg {
    padding: 1rem;
  }
}
